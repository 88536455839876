import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

//element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

//全局样式表
import "./sass/sass.scss";

// 全局方法
import Common from './utils/common.js'
Vue.prototype.$common = Common

//vueSwiper@3 = swiper@4
import vueSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.min.css'
Vue.use(vueSwiper)

//动态修改浏览器标题
import MyTitle from 'vue-wechat-title'
Vue.use(MyTitle)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Demo",
    component: () => import("../views/demo/demo")
  },
  {
    path: "/sk",
    name: "Sk",
    component: () => import("../views/demo/demoSk"),
    meta: {
      title: '数乾科技'
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;

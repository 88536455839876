
export default {

    /** 时间+-前后 **/
    /** @*curDate当前时间 @*type年月日类型 @*num+-年月日数 **/
    dateAdd: function (curDate, type, num) {
        var cur
        if (curDate) {
           cur = new Date(curDate);
        } else {
            cur = new Date();
        }
        var _num 
        if (num) {
            _num = num
        } else {
            _num = 0
        }
        if (type == 'year') {
            cur.setFullYear(cur.getFullYear() + _num)
        } else if (type == 'month') {
            cur.setMonth(cur.getMonth() + _num);
        } else {
            cur.setDate(cur.getDate() + _num);
        }
        var y = cur.getFullYear();
        var m = (cur.getMonth() + 1) < 10 ? "0" + (cur.getMonth() + 1) : (cur.getMonth() + 1); //获取当前月份的日期，不足10补0
        var d = cur.getDate() < 10 ? "0" + cur.getDate() : cur.getDate(); //获取当前几号，不足10补0
        if (type == 'year') {
            return y
        } else if (type == 'month') {
            return y + "-" + m;
        } else {
            return y + "-" + m + "-" + d;
        }
    },

    // !中国标准时间转换
    data_china: function(str){
        function checkTime(i){ 
            if(i<10){ 
                i='0'+i 
            } 
            return i 
        }
        var date = new Date(str)
        return date.getFullYear()+'-'+checkTime(date.getMonth()+1)+'-'+checkTime(date.getDate()) + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
    },
  
    /** 特殊符号(转出) **/
    html_encode: function(str){
        var s = ''
        if (str.length === 0) return ''
        s = str.replace(/&/g, '&amp;')
        s = s.replace(/</g, '&lt;')
        s = s.replace(/>/g, '&gt;')
        s = s.replace(/ /g, '&nbsp;')
        s = s.replace(/'/g, '&#39;')
        s = s.replace(/“/g, '&rdquo;')
        s = s.replace(/”/g, '&rdquo;')
        s = s.replace(/\n/g, '<br>')
        return s
    },

    /** 特殊符号(转入) **/
    html_decode: function(str){
        var s = ''
        if (str.length === 0) return ''
        s = str.replace(/&amp;/g, '&')
        s = s.replace(/&lt;/g, '<')
        s = s.replace(/&gt;/g, '>')
        s = s.replace(/&nbsp;/g, ' ')
        s = s.replace(/&#39;/g, '\'')
        s = s.replace(/&ldquo;/g, '“')
        s = s.replace(/&rdquo;/g, '”')
        s = s.replace(/<br>/g, '\n')
        s = s.replace(/\r/g, '<br>')
        return s
    },
    
    //! （公用）映射函数
    mapping: function(datas, ele, callback) {
        var newData = {}
        datas.forEach((item, index) => {
            var tmp = item[ele];
            if (!newData[tmp]) {
                var arr = [];
                arr.push(item);
                newData[tmp] = arr;
            } else {
                // newData[tmp] = item;
                newData[tmp].push(item);
            }
        })
        //遍历对象，判断插入数组值只有1个的时候，改为对象
        for (var index in newData){
            if(newData[index].length == 1){
                newData[index] = newData[index][0]
            }
        }
        //console.log('映射'+ele+':',newData)
        //获取字符串index需要使用for in语句进行访问
        callback && callback(newData)
    },

    //! 判断空字符串
	isEmpty: function(obj){
		if(typeof obj == "undefined" || obj == null || obj == ""){
			return true;
		}else{
			return false;
		}
	}


}
